import _ from 'lodash'
import Modernizr from 'modernizr'
import platform from 'platform'
import Swiper from 'swiper'
import { TweenMax, TimelineMax, Power1, Power4, CSSPlugin, AttrPlugin } from 'gsap/TweenMax'
import View from 'js/Component/View';
import Text from 'js/Component/Text'
import Animation from 'js/Component/Animation';
import Information from 'js/Component/Information';
import store from 'store';

class HomeView extends View {
    constructor() {
        super();

        // TEST
        if (typeof URLSearchParams !== 'undefined') {
            this.query = new URLSearchParams(window.location.search);
        }

        this.resize_timer = false;

        this.onestop = {
            slide: {},
            round: {}
        };

        this.onestop.slide.config = {
            lazy: false,
            navigation: {
                nextEl: '.js-swiper__btn--next',
                prevEl: '.js-swiper__btn--prev'
            },
            slideClass: 'js-swiper__item',
            wrapperClass: 'js-swiper__inner',
        };

        this.onestop.round.config = {
            $container: $('.js-round'),
            $childs: $('.js-round__item'),
            defaultScale: 1.1,
            duration: 100000,
            fps: 60
        };

        this.onestop.round.count = 0;
        //ボールが1周するフレーム数
        this.onestop.round.framesAtOnce = this.onestop.round.config.duration * this.onestop.round.config.fps / 1000;
    }

    init() {
        super.init();

        this.animation = new Animation();
        this.string = new Text();
        this.info = new Information();

        this.resize(window.innerWidth);

        window.addEventListener('resize', (e) => {
            if (this.resize_timer !== false) {
                clearTimeout(this.resize_timer);
            }

            this.resize_timer = setTimeout(() => {
                this.resize(window.innerWidth);
            }, 200);
        });

        this.render();
    }

    beforeRender() {
        super.beforeRender();

        this.setView();
        this.setObserve();
    }

    render() {
        super.render();
    }

    afterRender() {
        if ( Modernizr.touchevents ) {
            return false;
        }
        const self = this;
        const timeline = new TimelineMax();

        // TEST
        if (
            (typeof store.get('intro') === 'undefined' || store.get('intro') === false)
        ) {
            timeline
                .delay(0.4)
                .to($('.js-loading__path'), 0.6, {
                    strokeDashoffset: 0,
                    ease: Linear.easeInOut
                })
                .to($('.js-loading__company-name'), 0.8, {
                    autoAlpha: 1
                }, '+=1.4')
                .to($('.js-loading__bg'), 0.5, {
                    delay: 0.7,
                    scaleX: 0,
                    ease: Power1.easeIn,
                    onComplete: function(){
                        $('.js-loading').addClass('is-disabled');
                    }
                })
                .to($('.js-loading__svg'), 0.3, {
                    autoAlpha: 0,
                    ease: Power1.easeOut
                }, '-=1')
                .to($('.js-loading__company-name'), 0.3, {
                    autoAlpha: 0,
                    ease: Power1.easeOut
                }, '-=1');
                // .call(function() {
                //     self.animation.typeWrite($('.js-loading__lead'));
                // }, [], this, '+=1.2')
        }

        timeline
            .to($('.js-anim__hero-bg'), 0.4, {
                autoAlpha: 1,
                x: '0%',
                ease: Power1.easeOut
            })
            .staggerTo($('.js-anim__hero').find('.js-anim__hero__str'), 0.5, {
                y: '0%',
                ease: Power4.easeOut
            }, 0.02, '+=0', function() {
                TweenMax.to($('.js-anim__hero-slidefromleft')[0], 0.7, {x: 0, opacity: 1, ease: Power1.easeOut});
                store.set('intro', true);
                this.timeline.clear();
            });

        this.animateRound(this.onestop.round.config);
    }

    animateRound(config) {
        let framesAtOnce = config.duration * config.fps / 1000;

        config.$childs.each((index, item) => {
            let delay = (index - 1) / config.$childs.length;
            let frames = this.onestop.round.count + framesAtOnce * delay;
            let radius = this.animation.getRadius(config.$container.width() * 1.1, config.$childs.width(), config.defaultScale);

            item.style.top = '50%';
            item.style.left = '50%';
            item.style.zIndex = frames % framesAtOnce > framesAtOnce / 2 ? 0 : 3;
            item.style.transform = this.animation.getTransformValue(frames, radius, config);
        });

        this.onestop.round.count++;
    }

    cancelRound() {
        this.animation.cancelTicker();
        this.onestop.round.config.$childs.removeAttr('style');
    }

    setView() {
        /*
         * informationはModernizrで判定しない
         */
        this.info.init('#info', '/json/home.json');

        if (platform.name.toLowerCase() === 'safari' && platform.product === null) {
            $('.js-anim__hero').addClass('is-safari');
        }

        if ( Modernizr.touchevents ) {
            $('.js-loading').addClass('is-disabled');
            return false;
        }

        this.string.split('.js-anim__hero');
        this.string.changeColor($('.js-anim__hero').find('.js-anim__hero__str'), [0, 1, 2, 3, 7, 8, 9]);

        TweenMax.set($('.js-anim__hero-bg'), {
            autoAlpha: 0,
            x: '2.5%'
        });

        TweenMax.set($('.js-anim__hero').find('.js-anim__hero__str'), { y: '100%' });
        this.animation.setSlide('.js-anim__hero-slidefromleft', -40);
        // $('.js-anim__hero-typing').css('visibility', 'hidden');
        this.string.split('.js-heading');


        // TEST
        if (
            (typeof store.get('intro') !== 'undefined' && store.get('intro') === true)
        ) {
            $('.js-loading').addClass('is-disabled');
        }
    }

    setObserve() {
        if ( Modernizr.touchevents ) {
            return false;
        }

        const self = this;

        _.forEach(this.observe.element, (element, index) => {
            if ( $(element.watchItem).hasClass('js-heading') ) {
                this.animation.setHeading('.js-heading');
                element.fullyEnterViewport(function() {
                    self.animation.enterHeadingInView(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-dash') ) {
                this.animation.setDash(element.watchItem);
                element.fullyEnterViewport(function() {
                    self.animation.renderDash(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-typing') ) {
                element.fullyEnterViewport(function() {
                    self.animation.typeWrite(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-btn') ) {
                this.animation.setBtn(element.watchItem);
                element.fullyEnterViewport(function() {
                    self.animation.renderBtn(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-round') ) {
                element.enterViewport(function() {
                    self.animation.tickerFlag = true;
                    self.animation.ticker(() => {
                        self.animateRound(self.onestop.round.config);
                    });
                });
                element.exitViewport(() => {
                    this.animation.cancelTicker.call(this.animation);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-slidefromleft') ) {
                this.animation.setSlide(element.watchItem, -40);
                element.fullyEnterViewport(this.animation.renderSlide);
            }

            if ( $(element.watchItem).hasClass('js-anim-tags') ) {
                this.animation.setFilter(element.watchItem, true);
                element.fullyEnterViewport(this.animation.renderChildFilter);
            }

            if ( $(element.watchItem).hasClass('js-anim-filter') ) {
                this.animation.setFilter(element.watchItem, false, true);
                element.fullyEnterViewport(this.animation.renderFilter);
            }

            if ( $(element.watchItem).hasClass('js-anim-cards') ) {
                this.animation.setFilter(element.watchItem, true);
                element.enterViewport(this.animation.renderChildFilter);
            }
        });
    }

    resize(width) {
        if ( width < 768 ) {
            this.cancelRound();
            this.onestop.slide.swiper = new Swiper('.js-swiper', this.onestop.slide.config);
        } else {
            if ( typeof this.onestop.slide.swiper !== 'undefined' ) {
                this.onestop.slide.swiper.destroy(true, true);
            }

        }
    }
}

export default HomeView;
