import Modernizr from 'modernizr'

class Navigation {
    constructor() {
    }

    init() {
        if ( Modernizr.touchevents ) {
            $(document).on('touchend', '.js-nav-parent', (e) => {
                let href = '#' + $(e.target).data('href');
                if ( !$(href).hasClass('is-active') ) {
                    $('.js-nav-child-wrap').removeClass('is-active');
                    $(href).addClass('is-active');
                } else {
                    $('.js-nav-child-wrap').removeClass('is-active');
                }
            });
        } else {
            $(document).on('mouseenter', '.js-nav-parent', (e) => {
                let href = '#' + $(e.target).data('href');
                $(href).addClass('is-active');
            });

            $(document).on('mouseleave', '.js-nav-parent', (e) => {
                let href = '#' + $(e.target).data('href');
                $(href).removeClass('is-active');
            });

            $(document).on('mouseenter', '.js-nav-child', (e) => {
                $(e.target).parent().addClass('is-active');
            });

            $(document).on('mouseleave', '.js-nav-child', (e) => {
                $(e.target).parent().removeClass('is-active');
            });
        }
    }
}

export default Navigation;
