import Modernizr from 'modernizr'
import { TweenMax, Power1, CSSPlugin } from 'gsap/TweenMax'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

class Scroll {
    constructor() {
    }

    /*
    *
    *   @param scrollTime: Scroll time, scrollDistance: Distance. Use smaller value for shorter scroll and greater value for longer scroll
    *
    */
    initSmooth(scrollTime = 1.2, scrollDistance = 170) {
        if ( Modernizr.touchevents ) {
            return false;
        }

        var $window = $(window);

        $window.on('mousewheel DOMMouseScroll', (e) => {
            e.preventDefault();

            var delta = (e.originalEvent.wheelDelta / 120 || -(e.originalEvent.detail / 3));
            var scrollTop = $window.scrollTop();
            var finalScroll = scrollTop - parseInt(delta * scrollDistance);

            TweenMax.to($window, scrollTime, {
                scrollTo : {
                    y: finalScroll,
                    autoKill: true
                },
                ease: Power1.easeOut,   //For more easing functions see https://api.greensock.com/js/com/greensock/easing/package-detail.html
                autoKill: true,
                overwrite: 5
            });
        });

        return true;
    }

    initToTop() {
        let event = Modernizr.touchevents ? 'touchend' : 'click';

        $(document).on(event, '.js-scroll-to-top', () => {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        });
    }
}

export default Scroll;