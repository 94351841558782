import _ from 'lodash'
import Modernizr from 'modernizr'
import ScrollMonitor from 'scrollmonitor'

class Observe {
    constructor() {
        this.element = [];
    }

    init() {
        if ( Modernizr.touchevents ) {
            return false;
        }

        _.forEach(document.querySelectorAll('.js-observe'), (item, index) => {
            this.element[index] = scrollMonitor.create( item );
        });
    }
}

export default Observe;