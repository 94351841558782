import Modernizr from 'modernizr'

class Touch {
    constructor() {
    }

    initTouch() {
        if ( !Modernizr.touchevents ) {
            return false;
        }

        $(document).on('touchstart', '.js-touching', (e) => {
            $(e.target).addClass('is-touching');
        });

        $(document).on('touchend', '.js-touching', (e) => {
            $(e.target).removeClass('is-touching');
        });
    }
}

export default Touch;