import Modernizr from 'modernizr'
import { TweenMax, TimelineMax, Power1, Power4, CSSPlugin } from 'gsap/TweenMax'
import CSSRulePlugin from 'gsap/CSSRulePlugin'
import 'js/Vendor/typing'
import Text from 'js/Component/Text'

class Animation {
    constructor() {
        this.timeline;
        this.frame = 0;
        this.time = 0;
        this.timeRatio = 1;
        this.RAF;
        this.tickerFlag = false;
    }

    init() {
        if ( Modernizr.touchevents ) {
            return false;
        }
    }

    typeWrite(element) {
        if ( $(element).data().t ) {
            return false;
        }

        $(element).t({
            caret: false
        });
    }

    setHeading(element) {
        TweenMax.set($(element).find(element + '__str'), { y: '100%' });
    }

    enterHeadingInView(element) {
        TweenMax.staggerTo($(element).find('.js-heading__str'), 0.3, {y: '0%', ease: Power4.easeOut}, 0.02);
    }

    setDash(element) {
        $(element).addClass('u-dash');
    }

    renderDash(element) {
        $(element).addClass('is-animating');
    }

    setSlide(element, x = 0) {
        TweenMax.set($(element), { x: x, opacity: 0 });
    }

    renderSlide() {
        TweenMax.to(this.watchItem, 0.7, {x: 0, opacity: 1, ease: Power1.easeOut});
    }

    setFadein(element, x = 0) {
        TweenMax.set($(element), { opacity: 0 });
    }

    renderFadein() {
        TweenMax.to(this.watchItem, 0.7, {opacity: 1, ease: Power1.easeOut});
    }

    setFilter(element, child = false, absolute = false) {
        let color = $(element).data('color');
        let $element = !child ? $(element) : $(element).children();
        let $filter = !absolute ? 'u-filter__cover' : 'u-filter__cover--abs';

        $element.addClass('u-filter');
        TweenMax.set($element, { scaleX: 0, transformOrigin: '0% 50%' });
        $element.append('<div class="' + $filter + '"></div>');
        TweenMax.set($element.find('.' + $filter), {
            backgroundColor: this.getColor(color),
            transformOrigin: '100% 50%'
        });
    }

    renderChildFilter() {
        if ($(this.watchItem).children().find('.u-filter__cover').length <= 0) {
            return false;
        }

        const timeline = new TimelineMax();
        timeline
        .staggerTo($(this.watchItem).children(), 0.2, {
            scaleX: 1,
            ease: Power1.easeOut,
            onComplete: function() {
                $(this.target).parent().removeAttr('style');
            }
        }, 0.2)
        .staggerTo($(this.watchItem).children().find('.u-filter__cover'), 0.2, {
            scaleX: 0,
            ease: Power1.easeIn,
            onComplete: function() {
                $(this.target).remove();
            }
        }, 0.2, '-=0', function() {
            this.timeline.clear();
        });
    }

    renderFilter() {
        const timeline = new TimelineMax();
        timeline
        .to(this.watchItem, 0.2, {
            scaleX: 1,
            ease: Power1.easeOut
        })
        .set(this.watchItem, {
            clearProps: 'all'
        })
        .to($(this.watchItem).find('[class^="u-filter"]'), 0.2, {
            delay: 0.1,
            scaleX: 0,
            ease: Power1.easeIn,
            onComplete: function() {
                $(this.target).parent().removeAttr('style');
                $(this.target).remove();
                this.timeline.clear();
            }
        });
    }

    setBtn(element) {
        $(element).children().addClass('u-btn__line');
        let color = $(element).css('background-color');

        TweenMax.set($(element), { backgroundColor: 'transparent', color: 'transparent', pointerEvents: 'none', transition: 'none' });
        TweenMax.set($(element).find('.u-btn__line'), { visibility: 'hidden' });

        $(element).append('<div class="u-btn__filter"></div>');
        TweenMax.set($(element).find('.u-btn__filter')[0], { backgroundColor: color, scaleX: 0, transformOrigin: '0% 50%' });
    }

    renderBtn(element) {
        if ( !$(element).find('.u-btn__filter')[0] ) {
            return false;
        }

        const timeline = new TimelineMax();
        timeline
        .to($(element).find('.u-btn__filter')[0], 0.2, {
            scaleX: 1,
            ease: Power1.easeOut
        })
        .set($(element).find('.u-btn__line')[0], {
            clearProps: 'all'
        })
        .set(element, {
            clearProps: 'backgroundColor, color'
        })
        .set($(element).find('.u-btn__filter')[0], {
            transformOrigin: '100% 50%'
        })
        .to($(element).find('.u-btn__filter')[0], 0.2, {
            delay: 0.1,
            scaleX: 0,
            ease: Power1.easeIn,
            onComplete: function() {
                $(this.target).parent().removeAttr('style');
                $(this.target).remove();
                this.timeline.clear();
            }
        });
    }

    getColor(str) {
        let color;
        switch(str) {
            case 'primary':
                color = '#005283';
                break;
            case 'secondary':
                color = '#ff9c00';
                break;
            case 'tertiary':
                color = '#3db6ff';
                break;
            case 'odd':
                color = '#e5e5e5';
                break;
            case 'even':
                color = '#f7f7f7';
                break;
            case 'staffPrimary':
                color = '#8ADAEF';
                break;
            case 'staffSecondary':
                color = '#F5B500';
                break;
            case 'white':
                color = '#fff';
                break;
            default:
                color = '#000';
                break;
        }

        return color;
    }

    ticker(callback) {
        if ( Modernizr.touchevents || !this.tickerFlag ) {
            return false;
        }
        this.RAF = requestAnimationFrame(() => this.ticker(callback));
        this.frame++;
        this.updateTimeRatio();

        if ( this.frame % 2 === 0 ) {
            return;
        }

        callback();
    }

    cancelTicker() {
        cancelAnimationFrame(this.RAF);
        this.RAF = null;
        this.tickerFlag = false;
    }

    updateTimeRatio() {
      const lastTime = this.time;

      if ( lastTime > 0 ) {
        // 1フレーム当たりの時間(ミリ秒)
        const FPS_60_SEC = 1000 / 60;
        // 差分時間をセット
        const diff = new Date().getTime() - lastTime;
        // FPS60との比較係数をセット
        this.timeRatio = diff / FPS_60_SEC;
      }
      // 現在時間をセット
      this.time = new Date().getTime();
    }

    getTransformValue(count, radius, config) {
        let rad = (count * 1000 / config.duration / config.fps) * 2 * Math.PI;
        let slope = -30 * ( Math.PI / 180 );

        let tX = Math.floor((radius * Math.cos(rad) * Math.cos(slope) - (radius * 0.6) * Math.sin(rad) * Math.sin(slope)) * 1000) / 1000;
        let tY = Math.floor((radius * Math.cos(rad) * Math.sin(slope) + (radius * 0.6) * Math.sin(rad) * Math.cos(slope)) * 1000) / 1000;

        //文字列を返す
        return 'translate(' + tX + 'px, ' + tY + 'px) '
            + 'scale(' + (Math.floor(Math.sin(rad) * 100) / 100 * (config.defaultScale - 0.9) + config.defaultScale) + ')';
    }

    /*
    *  半径の取得
    *  @param
    *  @return Number 半径
    */
    getRadius(containerWidth, childWidth, defaultScale) {
        return (containerWidth - childWidth * defaultScale) / 2;
    }
}

export default Animation;
