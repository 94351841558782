import _ from 'lodash'
import TweenMax from 'gsap/TweenMax'

class Text {
    constructor() {
    }

    split(element) {
        var className = _.replace(element, '.', '');
        $(element).addClass('u-text');

        $(element).each(function(index, item) {
            var text = _.replace($(this).html(), /<(?!br)("[^"]*"|'[^']*'|[^'">])*>/g, '');
            text = _.replace(text, /\&amp\;/g, '&');
            text = _.replace(text, '<br>', ':').split('');
            var processed = '';

            _.forEach(text, function(str, index) {
                if ( text.indexOf(':') >= 0 && index === 0 ) {
                    processed += '<span class="u-text__container">';
                }

                if ( str === ' ' ) {
                    processed += '<span class="u-text__space"></span>';
                } else if ( str === ':' ) {
                    processed += '</span><span class="u-text__container">';
                } else {
                    processed += '<span class="u-text__str ' + className + '__str">' + str + '</span>';
                }

                if ( text.indexOf(':') >= 0 && index === text.length - 1 ) {
                    processed += '</span>';
                }
            });

            $(this).html(processed);
        });
    }

    changeColor($element, array) {
        $element.each(function(index) {
            if ( _.indexOf(array, index) >= 0 ) {
                $(this).addClass('u-tertiary-color');
            }
        });
    }
}

export default Text;
