import _ from 'lodash'
import Modernizr from 'modernizr'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment';

class Information {
    constructor() {
        this.limit = 4;
    }

    init(element, url) {
        this.list = new Vue({
            el: element,
            data() {
                return {
                    news: null,
                    page: 0
                }
            },
            created() {
                axios
                    .get(url)
                    .then((response) => {
                        var pad = 4 - response.data.length%4 !== 0 && response.data.length > 4 ? 4 - response.data.length%4 : 0;
                        for (var i = 0; i < pad; i++) {
                            response.data.push({});
                        }
                        this.news = response.data;
                        // this.news = response.data.sort((a, b) => {
                        //     return (new Date(a.date) < new Date(b.date) ? 1 : -1);
                        // });
                        this.news = _.map(this.news, (item, index) => {
                            item.id = index + 1;
                            if (typeof item.date !== 'undefined') {
                                item.date = moment(item.date).format('YYYY.M.D');
                            }
                            return item;
                        });
                        this.news = _.chunk(this.news, 4);
                    })
            },
            render(h) {
                if ( !this.news ) {
                    return false;
                }
                let list = _.map(this.news[this.page], function(item) {
                    return (
                        <li class="c-info__item" key={ item.id }>
                            <a href={ item.url !== '' ? item.url : null } class="c-info__link" target="_blank"></a>
                            <dl class="c-info__entry">
                                <dd class="c-info__entry-date">{ item.date }</dd>
                                <dt class="c-info__entry-title">{ item.title }</dt>
                            </dl>
                        </li>
                    );
                });

                let pager = [
                    (<a class={ this.page < 1 ? "c-info__pager--prev" : "c-info__pager--prev is-enabled" } on-click={ this.prev }>Prev</a>),
                    (<a class={ this.page >= this.news.length - 1 ? "c-info__pager--next" : "c-info__pager--next is-enabled" } on-click={ this.next }>Next</a>)
                ];
                
                return(
                    <dl class="c-info p-info">
                        <div class="c-info__title">Information</div>
                        <input id="info-toggle" class="c-info__checkbox" type="checkbox" />
                        <transition-group name="is" tag="ul" class="c-info__list">{ list }</transition-group>
                        <label for="info-toggle" class="c-info__accordion material-icons"></label>
                        <div class="c-info__pager">{ pager }</div>
                    </dl>
                )
            },
            methods: {
                prev() {
                    if ( this.page < 1 ) {
                        return false;
                    }
                    this.page--;
                },
                next() {
                    if ( this.page >= this.news.length - 1 ) {
                        return false;
                    }
                    this.page++;
                }
            }
        });

        // $(document).on('change', '#info-toggle', (e) => {
        //     let is_checked = $(e.target).prop('checked');
        //     if ( !is_checked ) {
        //         this.list.page = 0;
        //     }
        // });
    }

    render() {
    }
}

export default Information;
